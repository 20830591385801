import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import SplashScreen from './Screens/SplashScreen/SplashScreen.js'
import RoutesConstants from './RoutesConstants'
import LoginScreen from './Screens/LoginScreen/LoginScreen'
import HomeScreen from './Screens/HomeScreen'
import BookAppointmentScreen from './Screens/BookAppointmentScreen/BookAppointmentScreen.js'
import AppointmentList from './Screens/AppointmentList/AppointmentList.js'
import DoctorList from './Screens/DoctorList/DoctorList.js'
import ExerciseList from './Screens/ExerciseList/Exerciselist.js'
import Profile from './Screens/Profile'
import RequestAppointment from './Screens/RequestAppointment/RequestAppointment.js'
import { useEffect } from 'react'
import FirebaseUtil from './FirebaseUtil/FirebaseUtil'
import ConnectScreen from './Screens/ConnectScreen/ConnectScreen.js'

function checkIfAndroid(link) {
  const userAgent = window.navigator.userAgent
  if (userAgent.toLowerCase().includes('android') && window.userNotifiedForAndroid !== true) {
    window.userNotifiedForAndroid = true
    window.openAndroidPlayStore = () => {
      window.open(link)
      window.M.Toast.dismissAll()
    }
    window.dismissAndroidToast = () => {
      window.M.Toast.dismissAll()
    }
    // is android
    const html = '<span>Download our Android App</span>' +
      '<button class="btn-flat toast-action" onclick="javascript:window.openAndroidPlayStore();">Open</button>' +
      '<button class="btn-flat toast-action" onclick="javascript:window.dismissAndroidToast();">Dismiss</button>'
    console.log(html)
    window.M.toast({ html: html, displayLength: 10000 })
  }
}

function App() {
  useEffect(() => {
    const firebaseApp = FirebaseUtil.initFirebase().firebaseApp
    firebaseApp.database().ref('customerApp/appLink').once('value').then(x => {
      if (x && x.val()) checkIfAndroid(x.val())
    })
  }, [])

  return (
    <Router>
      <Switch>
        <Route path={RoutesConstants.Splash} exact>
          <SplashScreen />
        </Route>
        <Route path={RoutesConstants.Login}>
          <LoginScreen />
        </Route>
        <Route path={RoutesConstants.Home}>
          <HomeScreen />
        </Route>
        <Route path={RoutesConstants.Book}>
          <BookAppointmentScreen />
        </Route>
        <Route path={RoutesConstants.Appointments}>
          <AppointmentList />
        </Route>
        <Route path={RoutesConstants.Doctors}>
          <DoctorList />
        </Route>
        <Route path={RoutesConstants.Exercises}>
          <ExerciseList />
        </Route>
        <Route path={RoutesConstants.Profile}>
          <Profile />
        </Route>
        <Route path={RoutesConstants.Request}>
          <RequestAppointment />
        </Route>
        <Route path={RoutesConstants.Connect}>
          <ConnectScreen />
        </Route>
        <Route>
          <h1>Error 404</h1>
        </Route>
      </Switch>
    </Router>
  )
}

export default App

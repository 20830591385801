import React, { useState, useEffect } from 'react'
import FirebaseUtil from '../FirebaseUtil/FirebaseUtil'
import Utils from '../Utils'
import { useHistory } from 'react-router-dom'
import ProgressDialog from '../Components/ProgressDialog/ProgressDialog'
import RoutesConstants from '../RoutesConstants'
import NavHeader from '../Components/NavHeader'

function Profile (props) {
  const history = useHistory()

  const firebaseApp = FirebaseUtil.initFirebase().firebaseApp
  const [loading, setLoading] = useState(true)

  const [profile, setProfile] = useState({})

  const onInput = (e) => {
    if (e.target.name === 'image') {
      const uploadTask = firebaseApp
        .storage()
        .ref()
        .child('customer/' + profile.key)
        .put(e.target.files[0])
      uploadTask.on(
        'state_changed',
        function (snapshot) { },
        function (error) { },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firebaseApp.database()
              .ref('/customer/' + profile.key + '/profile/image').set(downloadURL)
            const customer = {
              ...profile,
              [e.target.name]: downloadURL
            }
            setProfile(customer)
            localStorage.setItem('customer', JSON.stringify(customer))
          })
        }
      )
    }
    setProfile({
      ...profile,
      [e.target.name]: e.target.value
    })
  }

  const saveProfile = (e) => {
    console.log('updating')
    e.preventDefault()
    const calendarInstance = window.M.Datepicker.getInstance(document.getElementById('dob'))
    const customer = {
      ...profile,
      dob: new Date(calendarInstance.toString()).getTime()
    }
    if (profile.key) {
      setLoading('Updating Profile')
      console.log('Updating customer: ', customer)
      firebaseApp.functions().httpsCallable('updateCustomer')({
        customer: customer
      }).then(r => {
        console.log('Updated Customer', r.data)
        if (r.data.result.success) {
          localStorage.setItem('customer', JSON.stringify({ profile: customer, key: profile.key }))
          window.M.toast({ html: 'Profile Saved' })
          setProfile(customer)
          history.push(RoutesConstants.Home)
        } else {
          window.M.toast({ html: `Error Updating Profile: <br>${r.data.result.message}` })
        }
        setLoading(false)
      })
        .catch(ex => {
          console.log('Error updating Customer', ex)
        })
    }
  }

  useEffect(() => {
    window.M.FormSelect.init(document.querySelectorAll('select'))
    window.M.Datepicker.init(document.getElementById('dob'), { yearRange: [1960, 2025] })
    window.M.updateTextFields()
    firebaseApp.auth().onAuthStateChanged((user) => {
      console.log(user.phoneNumber)
      const phoneNumber = user.phoneNumber.replace('+91', '')
      firebaseApp.database()
        .ref('/customer/')
        .orderByChild('profile/phone')
        .equalTo(phoneNumber)
        .once('value')
        .then(snap => {
          if (snap && snap.val()) {
            const p = Object.values(snap.val())[0].profile || {}
            console.log('profile', p)
            setProfile({
              ...p,
              key: Object.keys(snap.val())[0]
            })
            setLoading(false)

            const calendarInstance = window.M.Datepicker.getInstance(document.getElementById('dob'))
            calendarInstance.setDate(p.dob ? new Date(p.dob) : new Date())
            document.getElementById('dob').value = p.dob ? Utils.getDobFormat(new Date(p.dob)) : Utils.getDobFormat(new Date())
            window.M.updateTextFields()
          } else console.log('Doesnt exist')
        }).catch(err => {
          console.log('Error getting customerId', err)
        })
    })
  }, [])

  return (
    <div>
      {loading
        ? <ProgressDialog
          head='Loader'
          body='Loading Profile...'
          />
        : null}

      <NavHeader title='Profile' />

      <div class='container row mt-16'>
        <form class='col s12'>
          <div class='row'>
            <div class='file-field input-field'>
              <div class='profile-img-wrapper'>
                <img src={profile.image ? profile.image : '../images/default_profile.png'} alt='' class='profile-img' />
                <input type='file' name='image' onChange={onInput} />
              </div>
            </div>
          </div>
          <div class='input-field col s12'>
            <input disabled placeholder='Phone Number' type='tel' value={profile.phone || ''} />
            <label for='name'>Phone</label>
          </div>

          <div class='input-field col s12'>
            <input id='name' name='name' placeholder='Enter Name' type='text' class='validate' min='2' value={profile.name || ''} onChange={onInput} />
            <label for='name'>Name</label>
          </div>

          <div class='input-field col s12'>
            <input id='email' name='email' type='email' class='validate' value={profile.email || ''} onChange={onInput} />
            <label for='email'>Email</label>
          </div>

          <div class='input-field col s12'>
            <input id='dob' name='dob' type='text' class='datepicker' />
            <label for='dob'>DOB</label>
          </div>

          <div class='input-field col s12'>
            <select name='gender' onChange={onInput}>
              <option value='male' selected={profile.gender === 'male'}>Male</option>
              <option value='female' selected={profile.gender === 'female'}>Female</option>
            </select>
            <label>Gender</label>
          </div>

          <div class='input-field col s12'>
            <textarea id='textarea1' placeholder='Enter Address' name='address' class='materialize-textarea validate' min='4' rows='3' value={profile.address || ''} onChange={onInput} />
            <label for='textarea1'>Home Address</label>
          </div>

          <button style={{ width: '100%' }} class='btn waves-effect waves-light' type='submit' name='action' onClick={saveProfile}>Save Profile</button>
        </form>

      </div>
    </div>
  )
}

export default Profile
